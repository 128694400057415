<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>学术管理</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_referee_system',
                }"
                >裁判系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>团体赛</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card>
                    <el-row class="title_row">
                        <el-col>
                            <span>选择比赛</span>
                        </el-col>
                    </el-row>
                    <el-form label-width="80px">
                        <el-row :gutter="50">
                            <el-col :span="12">
                                <el-form-item label="赛季" prop="season">
                                    <el-select
                                        placeholder="请选择赛季"
                                        style="width: 100%"
                                        v-model="season"
                                        @change="groupTabClick"
                                    >
                                        <el-option
                                            v-for="item in dict_season"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="比赛组别">
                                    <el-select
                                        v-model="level"
                                        @change="groupTabClick"
                                    >
                                        <el-option
                                            v-for="item in dict_group"
                                            :key="item.dictCode"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="12"
                ><el-card  style="min-height: 145px;">
                    <el-col :span="20"
                        ><div>使用说明：</div>
                        <div class="operating-instruction">
                            比赛之前请先设置赛程和参赛队,开始比赛后不允许修改参赛队;小组积分赛/四分之一/二分之一决赛/季军赛/冠军赛操作流程（缺一不可）：1设置分组晋级策略；2生成对战数据；3上传抽签结果；4批量处理时间，房间号;
                        </div>
                    </el-col>
                </el-card>
            </el-col>
        </el-row>

        <el-row v-if="matchInfoType === '1'" :gutter="20">
            <el-col :span="12">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span>裁判功能</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col>
                            <el-button
                                type="primary"
                                @click="setItsoSchedualClicked"
                                v-if="
                                    showListData.matchflag ===
                                        'MATCHFLAG_INTEGRAL' ||
                                    !showListData.matchflag ||
                                    this.raceSchedule === '18'
                                "
                                >设置赛程</el-button
                            >
                            <el-button
                                type="primary"
                                @click="showSetJoinTeamDialog"
                                v-if="
                                    (showListData.matchflag &&
                                        showListData.matchflag ===
                                            'MATCHFLAG_INTEGRAL') ||
                                    this.raceSchedule === '18'
                                "
                                >设置参赛队</el-button
                            >
                            <el-button
                                v-if="
                                    showListData.matchflag &&
                                    showListData.matchflag !== 'QUALIFICATIONS'
                                "
                                type="primary"
                                @click="showSetGroupPromotionRuleDialog"
                                >设置分组晋级策略</el-button
                            >
                            <el-button
                                type="primary"
                                @click="generateBattleData"
                                v-if="showListData.matchflag"
                                >生成对战数据</el-button
                            >
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 18px">
                        <el-col>
                            <el-button
                                type="primary"
                                v-if="showListData.matchflag"
                                @click="
                                    uploadLotteryResultsDialogVisible = true
                                "
                                >上传抽签结果</el-button
                            >
                            <el-button
                                type="primary"
                                v-if="showListData.matchflag"
                                @click="setRoomTimeData"
                                >批量处理时间/房间号</el-button
                            >
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card
                    class="box-card"
                    style="margin-top: 15px; min-height: 180px"
                >
                    <el-row class="title_row" style="margin-bottom: 0px">
                        <el-col>
                            <span style="font-weight: bolder"
                                >操作须知:{{
                                    level === "1" ? "(初中组)" : "(小学组)"
                                }}</span
                            >
                        </el-col>
                    </el-row>
                    <span v-if="level === '0'" style="font-size: 14px; "
                        >1/4决赛 按积分赛排名 1v8 2v7 3v6 4v5
                        (需要在上传抽签结果时,在excel中指定) <br/>1/2决赛
                        按1/4决赛 P1vP8胜出 打 P3vP6胜出 / P2vP7胜出 打
                        P4vP5胜出 (需要在上传抽签结果时,在excel中指定)
                        <br/>季军赛 1/2决赛淘汰的两只队伍打 <br/>冠军赛
                        1/2决赛晋级的两只队伍打
                    </span>
                    <span v-if="level === '1'" style="font-size: 14px; "
                        >1/2决赛 按积分赛晋级的四支队伍,A1vB2 A2vB1
                        (需要在上传抽签结果时,在excel中指定)\ <br/>季军赛
                        1/2决赛淘汰的两只队伍打 <br/>冠军赛
                        1/2决赛晋级的两只队伍打
                    </span>
                </el-card>
            </el-col>
        </el-row>

        <el-tabs
            type="border-card"
            style="margin-top: 15px"
            v-model="matchInfoType"
            @tab-click="gameTypeTap()"
        >
            <el-tab-pane label="比赛管理" name="1">
                <el-tabs
                    v-model="matchManagerActiveIndex"
                    @tab-click="getMatchInfo('tabCard')"
                >
                    <el-tab-pane
                        v-for="(item, i) in matchInfo.teamMatchInfo"
                        :label="item.name"
                        :name="i + ''"
                        :key="i"
                    >
                        <el-row>
                            <el-col :span="12"
                                ><div style="fontsize: 16px">
                                    考题数量：{{ item.questionCount }}
                                </div></el-col
                            >
                        </el-row>
                        <div class="typeExplain_class">
                            {{ showListData.roomDescription }}
                        </div>
                        <el-table
                            header-cell-class-name="tableHeaderStyle"
                            style="margin-top: 15px"
                            :data="showListData.list"
                            border
                            stripe
                        >
                            <el-table-column
                                label="#"
                                type="index"
                            ></el-table-column>
                            <el-table-column
                                label="小组"
                                prop="group"
                                width="50px"
                            ></el-table-column>
                            <el-table-column
                                label="战队对阵"
                                prop="battle"
                            ></el-table-column>
                            <el-table-column
                                label="学校对阵"
                                prop="battleName"
                            ></el-table-column>
                            <el-table-column
                                label="时间"
                                prop="time"
                            ></el-table-column>
                            <el-table-column
                                label="房间号"
                                prop="roomId"
                                width="100px"
                                :filters="dict_roomList"
                                :filter-method="roomFilterHandler"
                            ></el-table-column>
                            <el-table-column
                                label="试卷"
                                prop="question"
                                width="100px"
                            ></el-table-column>
                            <el-table-column
                                label="比分"
                                prop="score"
                                width="50px"
                            ></el-table-column>
                            <el-table-column
                                label="状态"
                                prop="matchStatus"
                                width="100px"
                            >
                                <template slot-scope="scope">
                                    <el-tag
                                        :type="
                                            matchStateColor(
                                                scope.row.matchStatus
                                            )
                                        "
                                        size="mini"
                                        >{{
                                            matchStateFormatter(
                                                scope.row.matchStatus
                                            )
                                        }}</el-tag
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="备注"
                                width="200px"
                                prop="remark"
                            >
                                <template slot-scope="scope">
                                    <el-tooltip
                                        effect="dark"
                                        :content="scope.row.remark"
                                        placement="top"
                                    >
                                        <span>{{ scope.row.remark }}</span>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="200px">
                                <template scope="scope">
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        @click="
                                            showSetMatchRoomNumAndTimeDialog(
                                                scope.row
                                            )
                                        "
                                        :disabled="
                                            scope.row.state === '2' ||
                                            scope.row.state == '3'
                                                ? true
                                                : false
                                        "
                                        >编辑信息</el-button
                                    >
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        @click="
                                            showSetMatchScoreDialog(scope.row)
                                        "
                                        >设置比分</el-button
                                    ></template
                                >
                            </el-table-column>
                        </el-table>
                        <el-row
                            type="flex"
                            justify="center"
                            style="margin-top: 15px"
                            :gutter="20"
                        >
                            <el-col :span="3">
                                <el-button
                                    type="primary"
                                    size="medium"
                                    @click="
                                        showManualSetPromotionDialog(
                                            item.matchTeamIds,
                                            item.teamMatchTypeId
                                        )
                                    "
                                    style="width: 100%"
                                    v-if="item.showManualSetPromotion === '1'"
                                    >手动设置晋级</el-button
                                >
                            </el-col>
                            <el-col :span="3">
                                <el-button
                                    type="primary"
                                    size="medium"
                                    style="width: 100%"
                                    v-if="item.showManualGroupMatch === '1'"
                                    @click="
                                        showManualGroupMatchDialog(
                                            item.matchTeamIds,
                                            item.teamMatchTypeId
                                        )
                                    "
                                    >手动组赛</el-button
                                >
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="排名展示" name="2">
                <el-tabs
                    v-model="rankingShowActiveIndex"
                    @tab-click="getMatchInfo('tabCard')"
                >
                    <el-tab-pane
                        v-for="(item, i) in matchInfo.seasonMatchInfo"
                        :label="item.name"
                        :name="i + ''"
                        :key="i"
                    >
                        <div class="typeExplain_class">
                            {{ item.typeExplain }}
                        </div>
                        <el-table
                            header-cell-class-name="tableHeaderStyle"
                            :data="showListData.list"
                            :row-class-name="tableRowClassName"
                            border
                            v-if="showListData.type !== 'WINNERS'"
                        >
                            <el-table-column
                                label="#"
                                type="index"
                            ></el-table-column>
                            <el-table-column
                                label="小组"
                                prop="groupName"
                            ></el-table-column>
                            <el-table-column
                                label="队名"
                                prop="teamName"
                            ></el-table-column>
                            <el-table-column
                                label="积分率"
                                prop="integralRate"
                                :formatter="integralRateFormatter"
                            ></el-table-column>
                            <el-table-column
                                label="积分"
                                prop="point"
                            ></el-table-column>
                            <el-table-column
                                label="净胜分"
                                prop="left"
                            ></el-table-column>
                            <el-table-column
                                label="得/失"
                                prop="winLose"
                            ></el-table-column>
                            <el-table-column
                                label="胜/平/负"
                                prop="winPartLose"
                            ></el-table-column>
                            <el-table-column
                                label="小分"
                                prop="small"
                            ></el-table-column>
                            <el-table-column
                                label="场次"
                                prop="num"
                            ></el-table-column>
                        </el-table>
                        <el-table
                            header-cell-class-name="tableHeaderStyle"
                            :data="showListData.list"
                            :label="showListData.name"
                            :name="i + ''"
                            :key="i"
                            border
                            stripe
                            v-else
                        >
                            <el-table-column
                                label="#"
                                type="index"
                            ></el-table-column>
                            <el-table-column
                                property="schoolName"
                                label="学校名称"
                            ></el-table-column>
                            <el-table-column
                                property="clubName"
                                label="俱乐部名称"
                            ></el-table-column>
                            <el-table-column
                                property="itsoName"
                                label="战队名称"
                            ></el-table-column>
                            <el-table-column
                                label="积分率"
                                prop="integralRate"
                                :formatter="integralRateFormatter"
                            ></el-table-column>
                            <el-table-column
                                label="净胜分"
                                prop="netScore"
                            ></el-table-column>
                            <el-table-column
                                label="平均得分"
                                prop="averageScore"
                            ></el-table-column> </el-table
                    ></el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane
                label="最终排名"
                name="3"
                v-if="raceSchedule === '18' || isShowRank"
            >
                <el-table
                    header-cell-class-name="tableHeaderStyle"
                    :data="seasonMatchRankList"
                    border
                    stripe
                >
                    <el-table-column label="#" type="index"></el-table-column>
                    <el-table-column
                        property="schoolName"
                        label="学校名称"
                    ></el-table-column>
                    <el-table-column
                        property="clubName"
                        label="俱乐部名称"
                    ></el-table-column>
                    <el-table-column
                        property="itsoName"
                        label="战队名称"
                    ></el-table-column
                    ><el-table-column
                        property="ranking"
                        label="排名"
                        width="120px"
                    >
                        <template scope="scope">
                            <span style="color: red">{{
                                scope.row.ranking
                            }}</span>
                        </template>
                    </el-table-column></el-table
                >
            </el-tab-pane>
        </el-tabs>
        <!-- 设置参赛队的Dialog -->
        <el-dialog
            :title="currentMatchGroupName + '参赛队'"
            :visible.sync="setJoinTeamDialogVisible"
            width="60%"
        >
            <el-dialog
                width="50%"
                :title="currentMatchGroupName + '待添加参赛队'"
                :visible.sync="setJoinTeamInnerDialogVisible"
                append-to-body
            >
                <el-table
                    header-cell-class-name="tableHeaderStyle"
                    :data="wantJoinTeamData"
                    border
                    stripe
                    @selection-change="selectJoinTeam"
                >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column label="#" type="index"></el-table-column>
                    <el-table-column
                        property="schoolName"
                        label="学校名称"
                    ></el-table-column>
                    <el-table-column
                        property="clubName"
                        label="俱乐部名称"
                    ></el-table-column>
                    <el-table-column
                        property="name"
                        label="战队名称"
                    ></el-table-column>
                    <el-table-column property="signIn" label="签到状态">
                        <template scope="scope">
                            <el-tag
                                :type="scope.row.signIn ? 'success' : 'danger'"
                                size="mini"
                                >{{
                                    scope.row.signIn ? "已签到" : "未签到"
                                }}</el-tag
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <el-row type="flex" justify="center">
                    <el-button
                        type="primary"
                        style="margin-top: 15px"
                        @click="addJoinTeam"
                        >保存</el-button
                    >
                </el-row>
            </el-dialog>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="joinTeamDataList"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column
                    property="schoolName"
                    label="学校名称"
                ></el-table-column>
                <el-table-column
                    property="clubName"
                    label="俱乐部名称"
                ></el-table-column>
                <el-table-column
                    property="name"
                    label="战队名称"
                ></el-table-column>
                <el-table-column property="signIn" label="签到状态">
                    <template scope="scope">
                        <el-tag
                            :type="scope.row.signIn ? 'success' : 'danger'"
                            size="mini"
                            >{{
                                scope.row.signIn ? "已签到" : "未签到"
                            }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px">
                    <template scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-remove"
                            size="small"
                            @click="removeJoinTeam(scope.row)"
                            >移除</el-button
                        ></template
                    >
                </el-table-column>
            </el-table>
            <el-row
                type="flex"
                justify="center"
                style="margin-top: 15px"
                :gutter="20"
            >
                <el-button
                    type="primary"
                    size="medium"
                    @click="showSetJoinTeamInnerDialog"
                    >新增</el-button
                >
                <!-- <el-button
                        type="danger"
                        size="medium"
                        @click="saveJoinTeam"
                        >确认保存</el-button
                    > -->
                <el-button type="danger" size="medium" @click="resetJoinTeam"
                    >重新设置</el-button
                >
            </el-row>
        </el-dialog>
        <!-- 设置分组晋级策略的dialog -->
        <el-dialog
            :title="currentMatchGroupName + '分组晋级策略'"
            :visible.sync="setGroupPromotionRuleDialogVisible"
            width="60%"
        >
            <div>
                参赛队数：
                <span style="color: red; font-size: 20px">{{
                    groupPromotionRule.joinTeamNum
                }}</span>
            </div>
            <div style="margin-top: 15px">
                策略类型：
                <el-select
                    v-model="groupPromotionRule.type"
                    :disabled="groupPromotionRule.isSave"
                >
                    <el-option
                        v-for="item in dict_groupPromotionRule"
                        :key="item.dictCode"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                    ></el-option>
                </el-select>
            </div>
            <el-card
                style="margin-top: 15px"
                v-show="groupPromotionRule.type === 1"
            >
                <el-row>
                    晋级队伍数:
                    <el-input
                        v-model="groupPromotionRule.type1.promotionNum"
                        placeholder="请输入"
                        type="number"
                        style="width: 30%"
                        :disabled="groupPromotionRule.isSave"
                    ></el-input>
                </el-row>
                <el-row style="margin-top: 15px">
                    "小组积分赛"分组:
                    <el-input
                        v-model="groupPromotionRule.type1.groups"
                        placeholder="请输入(例:4,4,4,3),数字请以逗号分隔"
                        style="width: 50%"
                        :disabled="groupPromotionRule.isSave"
                    ></el-input>
                </el-row>
            </el-card>
            <el-card
                style="margin-top: 15px"
                v-show="groupPromotionRule.type === 2"
            >
                晋级队伍数:
                <el-input
                    v-model="groupPromotionRule.type2.promotionNum"
                    placeholder="请输入"
                    type="number"
                    style="width: 30%"
                    :disabled="groupPromotionRule.isSave"
                ></el-input>
                <el-button
                    type="primary"
                    size="medium"
                    style="margin-left: 15px"
                    @click="addGroupPromotionRuleManually()"
                    :disabled="groupPromotionRule.isSave"
                    >加一轮</el-button
                >
                <el-button
                    type="primary"
                    size="medium"
                    style="margin-left: 15px"
                    @click="reduceGroupPromotionRuleManually()"
                    :disabled="groupPromotionRule.isSave"
                    >减一轮</el-button
                >
                <el-table
                    :data="groupPromotionRule.type2.groupPromotionRuleManually"
                >
                    <el-table-column
                        label="轮次"
                        type="index"
                    ></el-table-column>
                    <el-table-column label="分组">
                        <editable-cell
                            slot-scope="{ row }"
                            v-model="row.groups"
                            can-edit
                        >
                            <span slot="content">{{ row.groups }}</span>
                        </editable-cell>
                    </el-table-column>
                    <el-table-column label="晋级策略">
                        <editable-cell
                            slot-scope="{ row }"
                            editable-component="el-select"
                            close-event="change"
                            v-model="row.policy"
                            can-edit
                        >
                            <span slot="content">{{
                                formatterItsoPromotionRule(
                                    null,
                                    null,
                                    row.policy
                                )
                            }}</span>
                            <template slot="edit-component-slot">
                                <el-option
                                    v-for="item in dict_itsoPromotionRule"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </template>
                        </editable-cell>
                    </el-table-column>
                    <el-table-column label="淘汰分组策略">
                        <editable-cell
                            slot-scope="{ row }"
                            editable-component="el-select"
                            close-event="change"
                            v-model="row.next"
                            can-edit
                        >
                            <span slot="content">{{
                                formatterItsoGroupRule(null, null, row.next)
                            }}</span>
                            <template slot="edit-component-slot">
                                <el-option
                                    v-for="item in dict_itsoGroupRule"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </template>
                        </editable-cell>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-card
                style="margin-top: 15px"
                v-show="groupPromotionRule.type === 3"
            >
                晋级队伍数:
                <el-input
                    v-model="groupPromotionRule.type3.promotionNum"
                    placeholder="请输入"
                    type="number"
                    style="width: 30%"
                    :disabled="groupPromotionRule.isSave"
                ></el-input>
                <el-button
                    type="primary"
                    size="medium"
                    style="margin-left: 15px"
                    @click="getGroupPromotionRulePreviewList"
                    :disabled="groupPromotionRule.isSave"
                    >策略预览</el-button
                >
                <el-table
                    header-cell-class-name="tableHeaderStyle"
                    :data="groupPromotionRulePreviewList"
                    border
                    stripe
                    @selection-change="selectGroupPromotionRule"
                >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column label="策略内容">
                        <template scope="scope">
                            <el-table :data="scope.row" style="width: 100%">
                                <el-table-column type="index" label="轮次">
                                </el-table-column>
                                <el-table-column prop="groups" label="分组">
                                </el-table-column>
                                <el-table-column
                                    prop="policy"
                                    label="晋级策略"
                                    :formatter="formatterItsoPromotionRule"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="next"
                                    label="淘汰分组策略"
                                    :formatter="formatterItsoGroupRule"
                                >
                                </el-table-column>
                                <el-table-column prop="matchNum" label="比赛数">
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-row type="flex" justify="center" style="margin-top: 15px">
                <el-button
                    type="primary"
                    size="medium"
                    @click="setGroupPromotionRule"
                    v-if="!groupPromotionRule.isSave"
                    >确 定</el-button
                >
                <el-button
                    type="danger"
                    size="medium"
                    @click="resetGroupPromotionRule"
                    v-if="groupPromotionRule.isSave && isReset"
                    >重新设置</el-button
                >
            </el-row>
        </el-dialog>
        <!-- 上传抽签结果的Dialog -->
        <el-dialog
            :title="currentMatchGroupName + '上传抽签结果'"
            :visible.sync="uploadLotteryResultsDialogVisible"
            width="30%"
            @close="uploadLotteryResultsDialogClose"
        >
            <el-upload
                :action="uploadLotteryResults.uploadUrl"
                ref="uploadLotteryResultsRef"
                :data="{
                    promotionRuleNum:
                        this.matchInfo.teamMatchInfo &&
                        this.matchInfo.teamMatchInfo.length !== 0 &&
                        this.matchInfo.teamMatchInfo[
                            this.matchManagerActiveIndex
                        ].promotionRuleNum
                            ? this.matchInfo.teamMatchInfo[
                                  this.matchManagerActiveIndex
                              ].promotionRuleNum
                            : -1,
                    raceSchedule: this.raceSchedule,
                    level: this.level,
                    matchTypeId:
                        this.matchInfo.teamMatchInfo &&
                        this.matchInfo.teamMatchInfo.length !== 0
                            ? this.matchInfo.teamMatchInfo[
                                  this.matchManagerActiveIndex
                              ].teamMatchTypeId
                            : -1,
                    activityType: this.$chnEngStatusCode.itsoActivityType,
                }"
                :headers="uploadLotteryResults.headerObj"
                :on-progress="uploadLotteryResultsProgress"
                :on-success="uploadLotteryResultsSuccess"
                :on-error="uploadLotteryResultsError"
                :on-exceed="uploadLotteryResultsExceed"
                :before-upload="uploadLotteryResultsBeforeUpload"
                :on-change="onChangeLoad"
                :limit="1"
                :auto-upload="false"
            >
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    size="small"
                    @click="downLoaduploadLotteryResultsStencil"
                    >下载模版</el-button
                >
                <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                >
                <el-button
                    style="margin-left: 10px"
                    size="small"
                    type="success"
                    @click="submitUploadLotteryResults"
                    >上传抽签结果</el-button
                >
            </el-upload>
        </el-dialog>
        <!-- 批量处理的Dialog -->
        <el-dialog
            title="批量设置对阵信息"
            :visible.sync="batchProcessingDialogVisible"
            width="35%"
            @close="resetForm('setTheRaceTimeBatchesFormRef')"
        >
            <el-form
                :model="setTheRaceTimeBatchesForm"
                ref="setTheRaceTimeBatchesFormRef"
                :rules="setTheRaceTimeBatchesFormRules"
                label-width="120px"
            >
                <el-form-item label="对阵开始时间" prop="timeBegin">
                    <el-date-picker
                        v-model="setTheRaceTimeBatchesForm.timeBegin"
                        type="datetime"
                        @change="timeSelect"
                        placeholder="选择对阵开始时间"
                        value-format="timestamp"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="对阵房间号" prop="roomNum">
                    <el-input
                        v-model="setTheRaceTimeBatchesForm.roomNum"
                        placeholder="请输入对阵房间号（用“,”分隔）"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="中场休息时间" prop="breakTime">
                    <el-input
                        v-model="setTheRaceTimeBatchesForm.breakTime"
                        placeholder="请输入中场休息时间（单位：分钟）"
                        type="number"
                        :min="0"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="每场比赛时长" prop="teamMatchTime">
                    <el-input
                        v-model="setTheRaceTimeBatchesForm.teamMatchTime"
                        placeholder="请输入每场比赛时长（单位：分钟）"
                        type="number"
                        :min="0"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="danger" @click="resetAllTimesTap"
                    >重置所有时间</el-button
                >
                <el-button @click="batchProcessingDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="setTheRaceTimeBatches"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 设置比赛时间/房间号的dialog -->
        <el-dialog
            title="设置比赛时间/房间号"
            :visible.sync="setMatchRoomNumAndTimeDialogVisable"
            width="30%"
        >
            <el-form
                :model="setMatchRoomNumAndTimeForm"
                ref="setMatchRoomNumAndTimeFormRef"
                :rules="setMatchRoomNumAndTimeFormRules"
                label-width="80px"
            >
                <el-form-item label="对阵信息">
                    <el-input
                        v-model="setMatchRoomNumAndTimeForm.battle"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="对阵时间" prop="time">
                    <el-date-picker
                        v-model="setMatchRoomNumAndTimeForm.time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 100%"
                        value-format="timestamp"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="对阵房间" prop="roomNum">
                    <el-input
                        v-model="setMatchRoomNumAndTimeForm.roomNum"
                    ></el-input>
                </el-form-item>
                <el-form-item label="题型" prop="roomNum">
                    <el-input
                        v-model="setMatchRoomNumAndTimeForm.question"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="setMatchRoomNumAndTimeDialogVisable = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="setMatchRoomNumAndTime"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 设置比分的dialog -->
        <el-dialog
            title="设置比分"
            :visible.sync="setMatchScoreDialogVisable"
            width="60%"
            @close="initSetMatchScoreData()"
            :close-on-click-modal="false"
        >
            <!-- 设置加赛提醒的dialog -->
            <el-dialog
                width="30%"
                title="请选择要“加赛的题型”"
                :visible.sync="addSetMatchScoreDialogVisable"
                append-to-body
            >
                加赛题型:
                <el-select v-model="addSetMatchScoreName" style="width: 200px">
                    <el-option
                        v-for="item in addGameTypeList"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                    ></el-option>
                </el-select>
                <el-button
                    type="primary"
                    style="margin-left: 15px"
                    @click="addSetMatchScore"
                    >确 定</el-button
                >
            </el-dialog>
            <el-form label-width="80px">
                <el-form-item label="对阵信息"
                    >{{ setMatchScoreData.teamName1 }} vs
                    {{ setMatchScoreData.teamName2 }}</el-form-item
                >
                <el-form-item label="备注信息">
                    <el-input
                        style="width: 300px"
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="setMatchScoreData.remark"
                        autosize
                    ></el-input
                ></el-form-item>
                <el-form-item label="对阵题型"
                    ><el-select
                        v-model="setMatchScoreData.quizSet"
                        style="width: 300px"
                        :disabled="setMatchScoreData.isSave === '1'"
                    >
                        <el-option
                            v-for="item in dict_quizSet"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                    <el-button
                        style="margin-left: 15px"
                        type="primary"
                        @click="setMatchScoreQuiz"
                        v-if="setMatchScoreData.isSave === '0'"
                        >确认题型</el-button
                    >
                    <el-button
                        style="margin-left: 15px"
                        type="primary"
                        @click="setMatchScoreGetUser"
                        v-if="setMatchScoreData.isSave === '0'"
                        >获取排兵布阵</el-button
                    ></el-form-item
                >
            </el-form>
            <el-table
                :data="setMatchScoreData.scoreInfo"
                style="margin-top: 15px"
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="游戏名称" width="140">
                    <editable-cell slot-scope="{ row }" v-model="row.gameName">
                        <span slot="content">{{ row.gameName }}</span>
                    </editable-cell>
                </el-table-column>
                <el-table-column
                    :label="
                        setMatchScoreData.teamName1 +
                        '-' +
                        setMatchScoreData.schoolName1
                    "
                >
                    <el-table-column label="队员">
                        <template slot-scope="{ row }">
                            <el-select
                                v-model="row.user1"
                                :change="checkUser(row.user1)"
                            >
                                <el-option
                                    v-for="item in teamData1"
                                    :key="item.userId"
                                    :label="item.name"
                                    :value="item.userId"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="得分">
                        <template slot-scope="{ row }">
                            <el-input
                                v-model="row.score1"
                                :change="checkScore()"
                                type="number"
                            >
                            </el-input>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    :label="
                        setMatchScoreData.teamName2 +
                        '-' +
                        setMatchScoreData.schoolName2
                    "
                >
                    <el-table-column label="队员">
                        <template slot-scope="{ row }">
                            <el-select v-model="row.user2">
                                <el-option
                                    v-for="item in teamData2"
                                    :key="item.userId"
                                    :label="item.name"
                                    :value="item.userId"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="得分">
                        <template slot-scope="{ row }">
                            <el-input
                                v-model="row.score2"
                                :change="checkScore()"
                                type="number"
                            >
                            </el-input>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <div style="margin-top: 15px; font-size: 20px; text-align: center">
                {{ scoreShow }}
            </div>
            <el-row
                type="flex"
                justify="center"
                :gutter="20"
                style="margin-top: 15px"
            >
                <el-button type="primary" @click="showAddSetMatchScoreDialog"
                    >加赛</el-button
                >
                <el-button type="primary" @click="setMatchScore()"
                    >保存</el-button
                >
            </el-row>
        </el-dialog>
        <!-- 手动设置晋级的Dialog -->
        <el-dialog
            :title="currentMatchGroupName + '设置晋级'"
            :visible.sync="manualSetPromotionDialogVisable"
            width="60%"
        >
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="manualSetPromotionTeamData"
                border
                stripe
                @selection-change="selectPromotionTeam"
            >
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column
                    property="schoolName"
                    label="学校名称"
                ></el-table-column>
                <el-table-column
                    property="clubName"
                    label="俱乐部名称"
                ></el-table-column>
                <el-table-column
                    property="name"
                    label="战队名称"
                ></el-table-column>
            </el-table>
            <el-row type="flex" justify="center">
                <el-col :span="3">
                    <el-button
                        type="primary"
                        style="width: 100%; margin-top: 15px"
                        @click="manualSetPromotion"
                        >设置晋级</el-button
                    >
                </el-col>
            </el-row>
        </el-dialog>
        <!-- 手动组赛的dialog -->
        <el-dialog
            title="手动组赛"
            :visible.sync="manualGroupMatchDialogVisable"
            width="60%"
        >
            <el-row>
                <el-button type="primary" @click="addManualGroupMatchGroup()"
                    >加一组</el-button
                >
                <el-button type="primary" @click="reduceManualGroupMatchGroup()"
                    >减一组</el-button
                >
            </el-row>
            <el-form label-width="80px" style="margin-top: 15px">
                <div
                    v-for="(item, index) in manualGroupMatchData.group"
                    :key="index"
                >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="小组名称">
                                <el-input
                                    v-model="item.level"
                                    placeholder="请输入"
                                ></el-input> </el-form-item
                        ></el-col>
                        <el-col :span="12"
                            ><el-form-item label="队伍">
                                <el-select
                                    v-model="item.teamIds"
                                    multiple
                                    placeholder="请选择"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="item in manualGroupMatchTeamData"
                                        :key="item.id"
                                        :label="item.teamName"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select> </el-form-item
                        ></el-col>
                    </el-row>
                </div>
            </el-form>
            <el-row
                type="flex"
                justify="center"
                :gutter="20"
                style="margin-top: 15px"
            >
                <el-col :span="3">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="manualGroupMatch"
                        >组赛</el-button
                    >
                </el-col>
            </el-row>
        </el-dialog>
        <el-drawer
            title="设置赛程"
            :size="drawerWidth"
            :visible.sync="drawer"
            :before-close="handleClose"
        >
            <div style="width: 100%">
                <el-row :gutter="20">
                    <el-button
                        style="margin-left: 20px"
                        type="primary"
                        icon="el-icon-plus"
                        @click="getRoundList('add')"
                        plain
                        >新建</el-button
                    >
                </el-row>
            </div>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="schduleDataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="轮次名" prop="name"></el-table-column>
                <el-table-column label="轮次标识" prop="flag"></el-table-column>
                <el-table-column
                    label="组别"
                    prop="level"
                    :formatter="schedulGroupFormatter"
                ></el-table-column>
                <el-table-column label="排序" prop="round">
                    <template slot-scope="scope">
                        <div
                            class="blue-font-color"
                            @click="changeProject(scope.row, 'round')"
                        >
                            {{ scope.row.round }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="策略轮次" prop="promotionRuleNum">
                    <template slot-scope="scope">
                        <div
                            class="blue-font-color"
                            style="color: red"
                            @click="
                                changeProject(scope.row, 'promotionRuleNum')
                            "
                        >
                            {{ scope.row.promotionRuleNum }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="类型" prop="typeFlag">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.typeFlag === 'MATCHTYPE'"
                            >比赛</el-tag
                        >
                        <el-tag
                            type="primary"
                            v-else-if="scope.row.typeFlag === 'WINNERS'"
                            >名单</el-tag
                        >
                        <el-tag type="danger" v-else>待设置</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80px">
                    <template slot-scope="scope">
                        <!-- <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            @click="editSchduleClicked(scope.row)"
                            >编辑</el-button
                        > -->
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            style="color: #f56c6c"
                            @click="deleteSchduleDialog(scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination
                @size-change="schdulehandleSizeChange"
                @current-change="schdulehandleCurrentChange"
                :current-page="itsoSchedleQueryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="itsoSchedleQueryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="schduleTotal"
            >
            </el-pagination> -->
        </el-drawer>
        <!-- 编辑轮次弹窗 -->
        <el-dialog
            :title="isEditSchdule == true ? '编辑轮次' : '新增轮次'"
            :visible.sync="addSchduleDataDialog"
            width="50%"
            @close="addSchduleDialogClose"
        >
            <el-form
                :model="addSchduleForm"
                label-width="140px"
                ref="addSchduleFormRef"
                :rules="addSchduleRules"
                style="width: 100%"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="轮次标识" prop="flag">
                            <el-select
                                placeholder="请选择"
                                ref="addSchduleFlagRef"
                                v-model="addSchduleForm.flag"
                                @change="addSchduleFlagClicked($event)"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_itsoFlag"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="轮次名称" prop="name">
                            <el-input
                                v-model="addSchduleForm.name"
                                placeholder="请输入轮次名称"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="组别" prop="level">
                            <el-select
                                placeholder="请选择"
                                v-model="addSchduleForm.level"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_group"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序" prop="round">
                            <el-input
                                v-model="addSchduleForm.round"
                                placeholder="请输入排序"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="typeFlag">
                            <el-select
                                v-model="addSchduleForm.typeFlag"
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_typeFlag"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="晋级策略轮次"
                            prop="promotionRuleNum"
                        >
                            <el-input
                                v-model="addSchduleForm.promotionRuleNum"
                                placeholder="请输入"
                            >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="关联轮次名称" prop="typeidstrList">
                            <el-select
                                v-model="addSchduleForm.typeidstrList"
                                placeholder="请选择"
                                style="width: 100%"
                                @change="selectChange"
                                multiple
                                clearable
                            >
                                <el-option
                                    v-for="item in selectSchduleDataList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addSchduleDataDialog = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addSchduleDataClicked"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 修改排序和策略轮次 -->
        <el-dialog
            title="修改"
            :visible.sync="changeRoundOrrankVisible"
            width="30%"
        >
            <el-input v-model="changeData"></el-input>
            <div slot="footer" class="dialog-footer">
                <el-button @click="changeRoundOrrankVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveChangeData"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EditableCell from '../../../../components/EditableCell.vue'
// editItsoRefereeMatchType
import {
  itsoRefereeMatchGetPage, createItsoRefereeMatchType, deleteItsoRefereeMatchType, itsoRefereeMatchGetInfo,
  getRemainTeamList, getJoinMatchTeamList, setJinMatch, removeJoinMatch, resetJoinMatch, createTeamMatch, getCurrentPromoPolicy,
  generateSeasonmatchGroupsByPolicy, getTeamRefereeNum, getItsoSeasonMatch, resetPromoPolicy, updateTimeAndRoomNum, resetTimeAndRoomNum,
  itsoRefereeUpdatetimeStr, getSeasonmatchScore, submitTeamMatchScore, getItsoUsers, setQuizSet, getPlayersList, editPromotionRuleNum, editMatchRound
} from '@/http/api'

export default {
  components: {
    EditableCell
  },
  data () {
    return {
      season: this.$chnEngStatusCode.itsoDefaultSeason,
      seasonMatchRankList: [],
      changeRoundOrrankVisible: false,
      changeData: '',
      selectSchduleDataList: [],
      drawerWidth: '50%',
      addSchduleDataDialog: false,
      schduleDataList: [],
      schduleTotal: 0,
      isShowRank: false,
      dict_itsoFlag: [],
      addSchduleRules: {
        flag: [{ required: true, message: '请输入', trigger: 'blur' }],
        level: [{ required: true, message: '请输入', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        round: [{ required: true, message: '请输入', trigger: 'blur' }],
        state: [{ required: true, message: '请选择', trigger: 'change' }],
        typeFlag: [{ required: true, message: '请输入', trigger: 'blur' }],
        promotionRuleNum: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      itsoSchedleQueryForm: {
        activityType: this.$chnEngStatusCode.itsoActivityType,
        pageNum: 1,
        pageSize: 100,
        raceSchedule: this.$route.query.scheduleId,
        flag: '',
        level: '',
        name: '',
        round: null,
        typeFlag: '',
        promotionRuleNum: '',
        typeidstrList: []
      },
      addSchduleForm: {
        activityType: this.$chnEngStatusCode.itsoActivityType,
        raceSchedule: this.$route.query.scheduleId,
        flag: '',
        level: '',
        name: '',
        round: null,
        promotionRuleNum: '',
        typeFlag: '',
        typeidstrList: []
      },
      isEditSchdule: false,
      drawer: false,
      showListData: {},
      dict_roomList: [],
      roomList: [],
      isUploadFile: false, // 是否已选择上传文件
      raceSchedule: this.$route.query.scheduleId, // 17-全国半决赛，18-全国总决赛
      roomIdActive: '',
      level: '0', // 0-小学组，1-初中组，2-高中组
      matchTypeId: '', // 赛程id
      // currentMatchGroupName: '"全国半决赛-小学组"',
      currentMatchGroupName: '',
      setJoinTeamDialogTitle: '',
      matchInfo: {
        teamMatchInfo: [],
        seasonMatchInfo: []
      },
      batchProcessingDialogVisible: false,
      setJoinTeamDialogVisible: false,
      setJoinTeamInnerDialogVisible: false,
      joinTeamDataList: [],
      wantJoinTeamData: [],
      wantJoinTeamId: [],
      setGroupPromotionRuleDialogVisible: false,
      dict_itsoPromotionRule: this.$userInfo.getDataList(this, 't_itso_promotion_rule').then(value => { this.dict_itsoPromotionRule = value }),
      dict_itsoGroupRule: this.$userInfo.getDataList(this, 't_itso_group_rule').then(value => { this.dict_itsoGroupRule = value }),
      dict_matchType: [
        {
          dictCode: 1,
          dictLabel: '全国半决赛',
          dictValue: '1'
        },
        {
          dictCode: 2,
          dictLabel: '全国总决赛',
          dictValue: '2'
        }
      ],
      dict_groupPromotionRule: [
        {
          dictCode: 1,
          dictLabel: '手动设置',
          dictValue: 1
        },
        {
          dictCode: 2,
          dictLabel: '根据条件创建',
          dictValue: 2
        }
        // ,
        // {
        //   dictCode: 3,
        //   dictLabel: '系统生成',
        //   dictValue: '3'
        // }
      ],
      dict_typeFlag: [
        {
          dictCode: 1,
          dictLabel: '比赛',
          dictValue: 'MATCHTYPE'
        },
        {
          dictCode: 2,
          dictLabel: '名单',
          dictValue: 'WINNERS'
        }
      ],
      dict_group: this.$userInfo.dict_schoolGroup(),
      dict_examstatus: this.$userInfo.dictExamstatus(),
      groupPromotionRule: {},
      groupPromotionRulePreviewList: [], // 系统生成-分组晋级策略预览集合
      uploadLotteryResultsDialogVisible: false,
      uploadLotteryResults: {
        uploadUrl: this.$http.defaults.baseURL + 'itsoReferee/importLotteryResults',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      setMatchRoomNumAndTimeDialogVisable: false,
      setTheRaceTimeBatchesForm: {
        roomNum: '',
        breakTime: '15',
        teamMatchTime: '45'
      },
      setTheRaceTimeBatchesFormRules: {
        timeBegin: [{ required: true, message: '请选择对阵开始时间', trigger: 'change' }],
        roomNum: [{ required: true, message: '请输入房间号', trigger: 'blur' }],
        breakTime: [{ required: true, message: '请输入中场休息时间', trigger: 'blur' }],
        teamMatchTime: [{ required: true, message: '请输入每场比赛时长', trigger: 'blur' }]
      },
      setMatchRoomNumAndTimeForm: {},
      setMatchRoomNumAndTimeFormRules: {
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        roomNum: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      setMatchScoreDialogVisable: false,
      setMatchScoreData: {},
      scoreShow: '',
      dict_quizSet: [
        {
          dictCode: 1,
          dictLabel: 'SET-1',
          dictValue: '1'
        },
        {
          dictCode: 2,
          dictLabel: 'SET-2',
          dictValue: '2'
        },
        {
          dictCode: 3,
          dictLabel: 'SET-3',
          dictValue: '3'
        }
      ],
      gameTypeList: [
        {
          name: '速拼赛',
          quizSetType: '1,2,3'
        },
        {
          name: '字母接龙',
          quizSetType: '1,2,3'
        },
        {
          name: '线索拼词',
          quizSetType: '1'
        },
        {
          name: '慧眼识词',
          quizSetType: '1'
        },
        {
          name: '你问我答',
          quizSetType: '2'
        },
        {
          name: '九宫格拼词',
          quizSetType: '2'
        },
        {
          name: '纵横天下',
          quizSetType: '3'
        },
        {
          name: '词汇生花',
          quizSetType: '3'
        },
        {
          name: '词源拼词',
          quizSetType: '1,2,3'
        }
      ],
      dict_season: [],
      addSetMatchScoreDialogVisable: false,
      addSetMatchScoreName: '',
      teamData1: [], // 队伍1-队员信息
      teamData2: [], // 队伍2-队员信息
      matchInfoType: '1', // 比赛列表展示的类型，1-比赛管理，2-排名展示
      matchManagerActiveIndex: '0', // 比赛管理tab的当前下标
      rankingShowActiveIndex: '0', // 排名展示tab的当前下标
      addGameTypeList: [],
      manualSetPromotionDialogVisable: false,
      manualSetPromotionTeamData: [],
      manualSetPromotionData: {},
      manualGroupMatchDialogVisable: false,
      manualGroupMatchTeamData: [],
      manualGroupMatchData: {
        level: []
      },
      setMatchScoreQuizRefreshDataEnable: false
    }
  },
  created () {
    this.setCurrentMatchGroupName()
    this.initGroupPromotionRule()
    this.getSeason()
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.isPassed) {
        return 'success-row'
      } else {
        return ''
      }
    },
    getSeason () {
      this.$userInfo.getDataList(this, 't_season').then(value => {
        this.dict_season = value
      })
    },
    getSchduleDataList (type) {
      this.itsoSchedleQueryForm.typeFlag = ''
      this.itsoSchedleQueryForm.level = this.level
      itsoRefereeMatchGetPage(this.itsoSchedleQueryForm).then((res) => {
        this.schduleDataList = res.data.list
        this.schduleTotal = res.data.total
        if (type === 'set') {
          this.drawer = true
        } else {
          this.getMatchInfo()
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    addSchduleDialogClose () {
      this.$refs.addSchduleFormRef.resetFields()
    },
    // 设置赛程点击事件（获取）
    setItsoSchedualClicked () {
      console.log('this.$route.query.scheduleId', this.$route.query.scheduleId)
      this.dict_itsoFlag = this.$userInfo.getDataList(this, 'itso_referee_match_flag').then(value => { this.dict_itsoFlag = value })
      this.getSchduleDataList('set')
    },
    // 轮次标识选择事件
    addSchduleFlagClicked (event) {
      for (const key in this.dict_itsoFlag) {
        if (Object.hasOwnProperty.call(this.dict_itsoFlag, key)) {
          const element = this.dict_itsoFlag[key]
          if (element.dictValue === event) {
            this.addSchduleForm.name = element.dictLabel
            this.$forceUpdate()
          }
        }
      }
    },
    // 删除轮次
    deleteSchduleDialog (rowId) {
      this.$confirm('是否确认删除该轮次?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteItsoRefereeMatchType({ id: rowId }).then((res) => {
          this.$message.success('删除成功')
          this.getSchduleDataList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 编辑轮次点击
    editSchduleClicked (rowData) {
      this.getRoundList('edit', rowData)
    },
    // 获取轮次列表
    getRoundList (type, rowData) {
      this.itsoSchedleQueryForm.level = this.level
      this.itsoSchedleQueryForm.typeFlag = 'MATCHTYPE'
      this.itsoSchedleQueryForm.season = this.season
      itsoRefereeMatchGetPage(this.itsoSchedleQueryForm).then((res) => {
        this.selectSchduleDataList = res.data.list
        if (type === 'edit') {
          itsoRefereeMatchGetInfo({ id: rowData.id, activityType: this.$chnEngStatusCode.itsoActivityType, level: this.addSchduleForm.level }).then((res) => {
            this.addSchduleForm = res.data
            this.addSchduleForm.level = this.addSchduleForm.level + ''
            if (this.addSchduleForm.typeidstr !== '') {
              this.addSchduleForm.typeidstrList = this.addSchduleForm.typeidstr.split(',')
            }
            this.isEditSchdule = true
            this.addSchduleDataDialog = true
          }).catch((err) => {
            console.log('返回的err', err)
          })
        } else {
          this.addSchduleDataDialog = true
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    selectChange (e) {
      this.addSchduleForm.typeidstr = this.addSchduleForm.typeidstrList.join(',')
      console.log('e', e)
    },
    // 新增轮次内容保存点击事件
    addSchduleDataClicked () {
      if (this.addSchduleForm.typeidstrList.length === 0) {
        this.addSchduleForm.typeidstr = ''
      }
      // if (this.isEditSchdule) {
      //   editItsoRefereeMatchType(this.addSchduleForm).then((res) => {
      //     this.addSchduleDataDialog = false
      //     this.getSchduleDataList('saveChange')
      //   }).catch((err) => {
      //     console.log('返回的err', err)
      //   })
      // } else {
      this.addSchduleForm.season = this.season
      createItsoRefereeMatchType(this.addSchduleForm).then((res) => {
        this.addSchduleDataDialog = false
        this.getSchduleDataList('saveChange')
      }).catch((err) => {
        console.log('返回的err', err)
      })
      // }
    },
    handleClose () {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.drawer = false
        })
        .catch(_ => { })
    },
    removalArray (arr) {
      const res = new Map()
      return arr.filter(arr => !res.has(arr.roomId) && res.set(arr.roomId, arr.roomId))
    },
    goBack () {
      this.$router.back()
    },
    setCurrentMatchGroupName () {
      var temp = ''
      switch (this.raceSchedule) {
        case '17':
          temp = '"全国半决赛'
          break
        case '18':
          temp = '"全国总决赛'
          break
        default:
          temp = '"未知'
      }
      temp += '-'
      switch (this.level) {
        case '0':
          temp += '小学组"'
          break
        case '1':
          temp += '初中组"'
          break
        case '2':
          temp += '高中组"'
          break
        default:
          temp += '未知"'
      }
      this.currentMatchGroupName = temp
      this.getMatchInfo()
    },
    matchTabClick () {
      this.setCurrentMatchGroupName()
    },
    groupTabClick () {
      console.log('season', this.season)
      this.isShowRank = false
      this.matchManagerActiveIndex = '0'
      this.rankingShowActiveIndex = '0'
      this.matchInfoType = '1'
      this.setCurrentMatchGroupName()
    },
    // 展示类型切换
    gameTypeTap () {
      console.log('matchInfoType', this.matchInfoType)
      this.getMatchInfo()
    },
    timeSelect () {
      console.log('setTheRaceTimeBatchesForm', this.setTheRaceTimeBatchesForm)
    },
    setRoomTimeData () {
      this.setTheRaceTimeBatchesForm = {
        timeBegin: parseInt(new Date().getTime()),
        roomNum: '1,2,3,4,5',
        breakTime: '15',
        teamMatchTime: '45'
      }
      this.batchProcessingDialogVisible = true
    },
    getMatchInfo (type) {
      if (type === 'tabCard') {
        if (this.matchInfoType === '1') {
          this.teamMatchTypeId = this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId
        } else {
          this.teamMatchTypeId = this.matchInfo.seasonMatchInfo[this.rankingShowActiveIndex].teamMatchTypeId
        }
      } else {
        this.teamMatchTypeId = ''
        this.matchManagerActiveIndex = '0'
        this.rankingShowActiveIndex = '0'
      }
      getItsoSeasonMatch({
        activityType: this.$chnEngStatusCode.itsoActivityType,
        raceSchedule: this.raceSchedule,
        season: this.season,
        level: this.level,
        flag: this.matchInfoType,
        teamMatchTypeId: this.teamMatchTypeId
      }).then((res) => {
        if (type !== 'tabCard') {
          this.matchInfo = res.data
        }
        if (this.matchInfoType === '1') {
          if (res.data.teamMatchInfo.length === 0) {
            this.showListData = {}
          } else {
            const filterNum = res.data.teamMatchInfo.filter(function (item, index, arrs) {
              return item.flag === 'MATCHFLAG_FIRST'
            })
            this.isShowRank = filterNum.length !== 0
            this.showListData = res.data.teamMatchInfo[0]
            if (res.data.teamMatchInfo[0].roomList !== undefined) {
              this.dict_roomList = []
              res.data.teamMatchInfo[0].roomList.forEach(element => {
                this.dict_roomList.push({
                  text: element + '',
                  value: element + ''
                })
              })
            }
          }
        } else if (this.matchInfoType === '2') {
          if (res.data.seasonMatchInfo.length === 0) {
            this.showListData = {}
          } else {
            const filterNum = res.data.seasonMatchInfo.filter(function (item, index, arrs) {
              return item.flag === 'MATCHFLAG_FIRST_NAME'
            })
            this.isShowRank = filterNum.length !== 0
            this.showListData = res.data.seasonMatchInfo[0]
          }
        } else if (this.matchInfoType === '3') {
          this.seasonMatchRankList = res.data.seasonMatchInfo
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
      // const { data: res } = await this.$http.post('/entertainment/app/getItsoSeasonMatch', {
      //   raceSchedule: this.raceSchedule,
      //   level: this.level,
      //   flag: this.matchInfoType,
      //   matchTypeId: this.teamMatchTypeId
      // })
    },
    timeStampFormatter (row, col, cell) {
      return this.$xcUtils.timestamp2Date(parseInt(cell))
    },
    roomFilterHandler (value, row, column) {
      console.log(value)
      const property = column.property
      return row[property] === value
    },
    matchStateFormatter (cell) {
      switch (cell) {
        case '0': return '未开始'
        case '1': return '待设置队员'
        case '2': return '进行中'
        case '3': return '已结束'
        default: return '未知'
      }
    },
    matchStateColor (cell) {
      switch (cell) {
        case '0': return 'warning'
        case '1': return ''
        case '2': return 'success'
        case '3': return 'danger'
        default: return 'info'
      }
    },
    // ================   设置参赛队Dialog start  ==================
    showSetJoinTeamDialog () {
      var formData = {
        raceSchedule: this.raceSchedule,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType,
        season: this.season
      }
      getJoinMatchTeamList(formData).then((res) => {
        this.joinTeamDataList = res.data
        this.setJoinTeamDialogVisible = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // ================   新增参赛队Dialog start  ==================
    showSetJoinTeamInnerDialog () {
      var formData = {
        raceSchedule: this.raceSchedule,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType,
        season: this.season
      }
      getRemainTeamList(formData).then((res) => {
        this.wantJoinTeamData = res.data
        this.setJoinTeamInnerDialogVisible = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 选择战队点击事件
    selectJoinTeam (data) {
      this.wantJoinTeamId = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        this.wantJoinTeamId.push(element.id)
      }
    },
    addJoinTeam () {
      setJinMatch({ ids: this.wantJoinTeamId, level: this.level, activityType: this.$chnEngStatusCode.itsoActivityType }).then((res) => {
        this.$message.success('保存成功')
        this.showSetJoinTeamDialog()
        this.setJoinTeamInnerDialogVisible = false
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // saveJoinTeam () {
    //   this.$confirm('保存后将不能再修改参赛队, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(async () => {
    //     const { data: res } = await this.$http.get('/erp/academic/judge/itso/saveItsoTeamJoinMatchRecord', {
    //       params: {
    //         raceSchedule: this.raceSchedule,
    //         level: this.level,
    //         activityType: this.$chnEngStatusCode.itsoActivityType
    //       }
    //     })
    //     if (res.code !== 0) {
    //       return this.$message.error(res.message)
    //     }
    //     this.$message.success('保存成功')
    //     this.setJoinTeamDialogVisible = false
    //   }).catch(() => { })
    // },
    removeJoinTeam (rowData) {
      this.$confirm('确认将该队伍从参赛队中移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        removeJoinMatch({ id: rowData.id, activityType: this.$chnEngStatusCode.itsoActivityType, level: rowData.group }).then((res) => {
          this.$message.success('保存成功')
          this.showSetJoinTeamDialog()
          this.setJoinTeamInnerDialogVisible = false
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => { })
    },
    resetJoinTeam () {
      this.$confirm('同时将重置“分组晋级策略”、“抽签结果”、“赛程”，确认重新设置参赛队吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        resetJoinMatch({ raceSchedule: this.$route.query.scheduleId, level: this.level, activityType: this.$chnEngStatusCode.itsoActivityType, season: this.season }).then((res) => {
          this.$message.success('重置成功')
          this.getMatchInfo()
          this.showSetJoinTeamDialog()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => { })
    },
    // ================   设置参赛队Dialog end  ==================
    // ================   设置分组晋级策略的dialog start  =================
    initGroupPromotionRule () {
      this.groupPromotionRule = {
        joinTeamNum: '',
        isSave: false,
        type: '',
        type1: {
          promotionNum: '',
          groups: ''
        },
        type2: {
          promotionNum: '',
          groupPromotionRuleManually: [
            {
              groups: '',
              policy: '',
              next: ''
            }
          ]
        },
        type3: {
          promotionNum: '',
          selectGroupPromotionRule: []
        }
      }
      this.groupPromotionRulePreviewList = []
    },
    showSetGroupPromotionRuleDialog () {
      // this.getMatchInfo()
      var formData = {
        refereeMatchTypeId: this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId,
        raceSchedule: this.raceSchedule,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      }
      getTeamRefereeNum(formData).then((res) => {
        if (!res.data.isSave) {
          return this.$message.warning('请先设置参赛队，并保存')
        }
        this.initGroupPromotionRule() // 初始化晋级策略
        // 判断是否已经设置了晋级策略
        formData.season = this.season
        getCurrentPromoPolicy(formData).then((res2) => {
          if (JSON.stringify(res2.data) !== '{}' && res2.data.groupPromotionRule.isSave === '1') {
            this.groupPromotionRule.isSave = true
            switch (res2.data.groupPromotionRule.type) {
              case 1:
                this.groupPromotionRule.type = 1
                this.groupPromotionRule.type1 = res2.data.groupPromotionRule.type1
                break
              case 2:
                this.groupPromotionRule.type = 2
                this.groupPromotionRule.type2 = res2.data.groupPromotionRule.type2
                break
              case 3:
                this.groupPromotionRule.type = 3
                this.groupPromotionRule.type3 = res2.data.groupPromotionRule.type3
                this.groupPromotionRulePreviewList = [this.groupPromotionRule.type3.selectGroupPromotionRule]
                break
              default:
                return this.$message.error('系统晋级策略返回值有误')
            }
          }
          this.groupPromotionRule.joinTeamNum = res.data.num
          this.setGroupPromotionRuleDialogVisible = true
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    async getGroupPromotionRulePreviewList () {
      if (this.groupPromotionRule.type3.promotionNum === '') return this.$message.warning('请先填写晋级队伍数')
      const { data: res } = await this.$http.get('/erp/academic/judge/itso/getSystemGroupPromotionRule', {
        params: {
          totalTeams: this.groupPromotionRule.joinTeamNum,
          totalPass: this.groupPromotionRule.type3.promotionNum,
          level: this.level,
          activityType: this.$chnEngStatusCode.itsoActivityType
        }
      })
      console.log('getGroupPromotionRulePreviewList', res)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.groupPromotionRulePreviewList = res.data
    },
    selectGroupPromotionRule (data) {
      this.groupPromotionRule.type3.selectGroupPromotionRule = data
    },
    async setGroupPromotionRule () {
      switch (this.groupPromotionRule.type) {
        case 1:
          if (this.groupPromotionRule.type1.promotionNum === '' ||
                        this.groupPromotionRule.type1.groups === '') {
            return this.$message.warning('请完善策略信息')
          }
          break
        case 2:
          if (this.groupPromotionRule.type2.promotionNum === '') {
            return this.$message.warning('请完善策略信息')
          }
          break
        case 3:
          if (this.groupPromotionRule.type3.promotionNum === '') {
            return this.$message.warning('请完善策略信息')
          }
          if (this.groupPromotionRule.type3.selectGroupPromotionRule.length !== 1) {
            return this.$message.warning('请选择一个晋级策略')
          } else {
            // 去掉外层数组
            this.groupPromotionRule.type3.selectGroupPromotionRule = this.groupPromotionRule.type3.selectGroupPromotionRule[0]
          }
          break
        default: return this.$message.warning('请完善策略信息')
      }
      generateSeasonmatchGroupsByPolicy({
        refereeMatchTypeId: this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId,
        raceSchedule: this.raceSchedule,
        activityType: this.$chnEngStatusCode.itsoActivityType,
        level: this.level,
        season: this.season,
        groupPromotionRule: this.groupPromotionRule
      }).then((res2) => {
        this.$message.success('保存成功')
        this.getMatchInfo('tabCard')
        this.setGroupPromotionRuleDialogVisible = false
      })
    },
    addGroupPromotionRuleManually () {
      this.groupPromotionRule.type2.groupPromotionRuleManually.push(
        {
          groups: '',
          policy: '',
          next: ''
        }
      )
    },
    reduceGroupPromotionRuleManually () {
      console.log(this.groupPromotionRule.type2.groupPromotionRuleManually)
      if (this.groupPromotionRule.type2.groupPromotionRuleManually.length === 1) {
        return this.$message.warning('至少进行一轮')
      }
      this.groupPromotionRule.type2.groupPromotionRuleManually.splice(
        this.groupPromotionRule.type2.groupPromotionRuleManually.length - 1, 1
      )
    },
    resetGroupPromotionRule () {
      this.$confirm('同时将重置“抽签结果”、“赛程”，确认重新设置分组晋级策略吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        resetPromoPolicy({
          season: this.season,
          raceSchedule: this.raceSchedule,
          activityType: this.$chnEngStatusCode.itsoActivityType,
          level: this.level,
          matchTypeId: this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId,
          flag: 2 // 重置策略及对阵等等
        }).then((res2) => {
          this.$message.success('重置成功')
          this.getMatchInfo()
          this.setGroupPromotionRuleDialogVisible = false
        })
      }).catch(() => { })
    },
    formatterItsoPromotionRule (row, col, policy) {
      var temp = ''
      this.dict_itsoPromotionRule.forEach(element => {
        if (element.dictValue === policy) {
          temp = element.dictLabel
        }
      })
      return temp
    },
    formatterItsoGroupRule (row, col, next) {
      var temp = ''
      this.dict_itsoGroupRule.forEach(element => {
        if (element.dictValue === next) {
          temp = element.dictLabel
        }
      })
      return temp
    },
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 根据房间号查询列表
    roomIdChange () {

    },
    // ================   设置分组晋级策略的dialog end  =================
    // ================   上传抽签结果的Dialog start  =================
    uploadLotteryResultsProgress () {
      this.$curUtils.openLoading(this)
    },
    uploadLotteryResultsSuccess (res) {
      console.log('uploadLotteryResultsSuccess', res)
      this.$curUtils.closeLoading(this)
      this.isUploadFile = false
      if (res.code !== 200) {
        return this.$alert('请修改后再上传，失败原因:' + res.message, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('上传抽签结果成功')
      this.getMatchInfo('tabCard')
      this.$refs.uploadLotteryResultsRef.clearFiles()// 清除文件
      this.uploadLotteryResultsDialogVisible = false
    },
    uploadLotteryResultsError (error) {
      console.log('uploadLotteryResultsError', error)
      this.$curUtils.closeLoading()
      this.$message.error(error.msg)
    },
    downLoaduploadLotteryResultsStencil () {
      var temp = {
        raceSchedule: this.raceSchedule,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType,
        matchTypeId: this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId,
        promotionRuleNum: (this.matchInfo.teamMatchInfo && this.matchInfo.teamMatchInfo.length !== 0 && this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].promotionRuleNum) ? this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].promotionRuleNum : -1
      }
      var downLoadUrl = this.$env.baseIP + 'itsoReferee/downloadOfLotteryResults'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, temp)
    },
    uploadLotteryResultsExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.uploadLotteryResultsRef.clearFiles()// 清除文件
      this.$refs.uploadLotteryResultsRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    submitUploadLotteryResults () {
      if (this.isUploadFile) {
        this.$refs.uploadLotteryResultsRef.submit()
      } else {
        this.$message.error('请先选择要上传的文件')
      }
    },
    uploadLotteryResultsBeforeUpload () {
      console.log('isReset', this.isReset)
      return new Promise((resolve, reject) => {
        if (this.matchInfo.teamMatchInfo.length > 0) {
          this.$confirm('同时将重置“赛程”，确认重新上传抽签结果吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            resolve()
          }).catch(() => {
            this.isUploadFile = false
            reject(new Error('error'))
          })
        } else {
          resolve()
        }
      })
    },
    // 监听上传文件弹窗关闭事件
    uploadLotteryResultsDialogClose () {
      this.isUploadFile = false
    },
    // ================   上传抽签结果的Dialog end  =================
    // ================   批量设置比赛信息 start  =================
    setTheRaceTimeBatches () {
      this.$refs.setTheRaceTimeBatchesFormRef.validate(async valid => {
        if (!valid) return
        // 自动将房间号中的中文，转换为英文,
        this.setTheRaceTimeBatchesForm.roomNums = this.setTheRaceTimeBatchesForm.roomNum.replace(/，/g, ',')
        this.setTheRaceTimeBatchesForm.matchTypeId = this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId
        this.setTheRaceTimeBatchesForm.activityType = this.$chnEngStatusCode.itsoActivityType
        this.setTheRaceTimeBatchesForm.level = this.level
        this.setTheRaceTimeBatchesForm.raceSchedule = this.raceSchedule
        updateTimeAndRoomNum(this.setTheRaceTimeBatchesForm).then((res) => {
          this.$message.success('保存成功')
          this.getMatchInfo('tabCard')
          this.batchProcessingDialogVisible = false
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // ================   批量设置比赛信息 end  =================
    // ================   重置所有时间 start  =================
    async resetAllTimesTap () {
      this.$refs.setTheRaceTimeBatchesFormRef.validate(async valid => {
        if (!valid) return
        this.setTheRaceTimeBatchesForm.roomNums = this.setTheRaceTimeBatchesForm.roomNum.replace(/，/g, ',')
        this.setTheRaceTimeBatchesForm.matchTypeId = this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId
        this.setTheRaceTimeBatchesForm.activityType = this.$chnEngStatusCode.itsoActivityType
        this.setTheRaceTimeBatchesForm.level = this.level
        this.setTheRaceTimeBatchesForm.raceSchedule = this.raceSchedule
        resetTimeAndRoomNum(this.setTheRaceTimeBatchesForm).then((res) => {
          this.$message.success('重置成功')
          this.getMatchInfo()
          this.batchProcessingDialogVisible = false
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // ================   重置所有时间 end  =================
    // ================   设置比赛时间/房间号的dialog start  =================
    showSetMatchRoomNumAndTimeDialog (data) {
      var timeTemp = []
      if (data.time !== undefined && data.time.indexOf('-' > -1)) {
        timeTemp = data.time.split('-')
        timeTemp[0] = (new Date(timeTemp[0])).getTime()
        timeTemp[1] = (new Date(timeTemp[1])).getTime()
      }
      console.log('data', data)
      this.setMatchRoomNumAndTimeForm = {
        id: data.id,
        battle: data.battle,
        time: timeTemp,
        roomNum: data.roomId,
        question: data.question,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      }
      this.setMatchRoomNumAndTimeDialogVisable = true
    },
    setMatchRoomNumAndTime () {
      this.setMatchRoomNumAndTimeForm.roomname = this.setMatchRoomNumAndTimeForm.roomNum
      this.setMatchRoomNumAndTimeForm.timeStr = this.$xcUtils.timestamp3Date(this.setMatchRoomNumAndTimeForm.time[0]) + '-' + this.$xcUtils.timestamp3Date(this.setMatchRoomNumAndTimeForm.time[1])
      console.log('setMatchRoomNumAndTimeForm', this.setMatchRoomNumAndTimeForm)
      this.$refs.setMatchRoomNumAndTimeFormRef.validate(async valid => {
        if (!valid) return
        itsoRefereeUpdatetimeStr(this.setMatchRoomNumAndTimeForm).then((res) => {
          this.$message.success('保存成功')
          this.getMatchInfo()
          this.setMatchRoomNumAndTimeDialogVisable = false
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // ================   设置比赛时间/房间号的dialog end  =================
    // ================   设置比分的dialog start  =================
    initSetMatchScoreData () {
      this.setMatchScoreData = {
        isSave: false,
        teamMatchId: '',
        teamName1: '',
        teamName2: '',
        quizSet: '',
        remark: '',
        scoreInfo: [],
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      }
      this.scoreShow = ''
      this.addSetMatchScoreName = ''
      if (this.setMatchScoreQuizRefreshDataEnable) {
        this.getMatchInfo('tabCard')
        this.setMatchScoreQuizRefreshDataEnable = false
      }
    },
    // 生成对战数据
    generateBattleData () {
      console.log('this.matchInfo', this.matchInfo)
      if (this.matchInfo.teamMatchInfo && this.matchInfo.teamMatchInfo.length !== 0) {
        var formData = {
          refereeMatchTypeId: this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].teamMatchTypeId,
          level: this.level,
          season: this.season,
          activityType: this.$chnEngStatusCode.itsoActivityType,
          raceSchedule: this.raceSchedule,
          promotionRuleNum: (this.matchInfo.teamMatchInfo && this.matchInfo.teamMatchInfo.length !== 0 && this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].promotionRuleNum) ? this.matchInfo.teamMatchInfo[this.matchManagerActiveIndex].promotionRuleNum : -1
        }
        createTeamMatch(formData).then((res) => {
          this.$message.success('生成成功')
          this.getMatchInfo('tabCard')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        return this.$message.error('暂无赛程信息！')
      }
    },
    getTeamUsers (teamId1, teamId2, flag, teamMatchId) {
      getItsoUsers({
        teamId: flag === 1 ? teamId1 : teamId2,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      }).then((res) => {
        res.data.unshift({
          name: '请选择',
          userId: '-1'
        })
        if (flag === 1) {
          this.teamData1 = res.data.map(item => {
            item.name = item.name + (item.enName ? ('-' + item.enName) : '')
            return item
          })
          this.getTeamUsers(teamId1, teamId2, 2, teamMatchId)
        } else {
          this.teamData2 = res.data.map(item => {
            item.name = item.name + (item.enName ? ('-' + item.enName) : '')
            return item
          })
          this.setMatchScoreGetScore(teamMatchId)
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    setMatchScoreGetScore (teamMatchId) {
      getSeasonmatchScore({
        teamMatchId: teamMatchId,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      }).then((res) => {
        this.setMatchScoreData = res.data
        this.setMatchScoreDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    showSetMatchScoreDialog (data) {
      // if (data.time === '' || data.roomId === '') {
      //   return this.$message.warning('请先设置比赛时间、房间号')
      // }
      this.getTeamUsers(data.teamId1, data.teamId2, 1, data.id)
    },
    addSetMatchScore () {
      this.setMatchScoreData.scoreInfo.push(
        {
          gameName: this.addSetMatchScoreName,
          user1: '-1',
          score1: '0',
          user2: '-1',
          score2: '0'
        }
      )
      this.addSetMatchScoreDialogVisable = false
    },
    // 保存对阵信息
    setMatchScore () {
      if (this.setMatchScoreData.quizSet === '' || this.setMatchScoreData.scoreInfo.length === 0) {
        return this.$message.warning('请选择题型')
      }
      for (let index = 0; index < this.setMatchScoreData.scoreInfo.length; index++) {
        const element = this.setMatchScoreData.scoreInfo[index]
        if (element.user1 === '-1' || element.score1 === '' || element.user2 === '-1' || element.score2 === '') {
          return this.$message.warning('请完善比赛信息')
        }
      }
      this.setMatchScoreData.teamMatchTypeId = this.teamMatchTypeId
      this.setMatchScoreData.level = this.level
      this.setMatchScoreData.activityType = this.$chnEngStatusCode.itsoActivityType
      submitTeamMatchScore(this.setMatchScoreData).then((res) => {
        this.$message.success('保存成功')
        this.getMatchInfo('tabCard')
        this.setMatchScoreDialogVisable = false
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    formatterTeamUser (userId, flag) {
      var tempName = '请选择'
      var tempList = flag === 1 ? this.teamData1 : this.teamData2
      tempList.forEach(element => {
        if (element.userId === userId) {
          tempName = element.name
        }
      })
      return tempName
    },
    setMatchScoreQuiz () {
      if (this.setMatchScoreData.quizSet === '') {
        return this.$message.warning('请先选择题型')
      }
      this.setMatchScoreData.level = this.level
      this.setMatchScoreData.activityType = this.$chnEngStatusCode.itsoActivityType
      this.setMatchScoreData.setNum = this.setMatchScoreData.quizSet
      this.setMatchScoreData.raceSchedule = this.raceSchedule
      setQuizSet(this.setMatchScoreData).then((res) => {
        this.$message.success('确认成功')
        this.setMatchScoreData.scoreInfo = [] // 重置
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          this.setMatchScoreData.scoreInfo.push(
            {
              gameName: element,
              user1: '-1',
              score1: '0',
              user2: '-1',
              score2: '0'
            }
          )
        }
        // 确认题型后，关闭弹窗时，刷新对阵列表
        this.setMatchScoreQuizRefreshDataEnable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    setMatchScoreGetUser () {
      if (this.setMatchScoreData.scoreInfo.length === 0) {
        return this.$message.warning('请先确认题型')
      }
      getPlayersList({
        teamMatchId: this.setMatchScoreData.teamMatchId
      }).then((res) => {
        for (let index = 0; index < 5; index++) {
          this.setMatchScoreData.scoreInfo[index].user1 = res.data.playerIds1[index]
          this.setMatchScoreData.scoreInfo[index].user2 = res.data.playerIds2[index]
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    showAddSetMatchScoreDialog () {
      this.addGameTypeList = []
      if (this.setMatchScoreData.quizSet === '') {
        return this.$message.warning('请先选择题型')
      }
      for (let index = 0; index < this.gameTypeList.length; index++) {
        const element = this.gameTypeList[index]
        if (element.quizSetType.indexOf(this.setMatchScoreData.quizSet) === -1) {
          this.addGameTypeList.push(element)
        }
      }
      this.addSetMatchScoreDialogVisable = true
    },
    checkUser (val) {
      if (val === '-1') return
      var setMatchScoreSelectedUser = []
      for (let index = 0; index < 5; index++) { // 只判断基础提醒队员不可重复
        const element = this.setMatchScoreData.scoreInfo[index]
        if (element.user1 !== '-1') {
          if (setMatchScoreSelectedUser.indexOf(element.user1) !== -1) {
            this.$message.warning('不可以重复设置队员')
            setMatchScoreSelectedUser.splice(setMatchScoreSelectedUser.indexOf(element.user1), 1)
            element.user1 = '-1'
          } else {
            setMatchScoreSelectedUser.push(element.user1)
          }
        }
        if (element.user2 !== '-1') {
          if (setMatchScoreSelectedUser.indexOf(element.user2) !== -1) {
            this.$message.warning('不可以重复设置队员')
            setMatchScoreSelectedUser.splice(setMatchScoreSelectedUser.indexOf(element.user2), 1)
            element.user2 = '-1'
          } else {
            setMatchScoreSelectedUser.push(element.user2)
          }
        }
      }
    },
    checkScore () {
      var temp1 = 0
      var temp2 = 0
      if (this.setMatchScoreData.scoreInfo.length > 0) {
        for (let index = 0; index < this.setMatchScoreData.scoreInfo.length; index++) {
          const element = this.setMatchScoreData.scoreInfo[index]
          if (parseInt(element.score1) < 0) {
            element.score1 = '0'
          }
          if (parseInt(element.score2) < 0) {
            element.score2 = '0'
          }
          if (element.score1 !== '' && element.score2 !== '') {
            if (parseInt(element.score1) > parseInt(element.score2)) { // 胜
              temp1 += 1
            } else if (parseInt(element.score1) < parseInt(element.score2)) { // 负
              temp2 += 1
            } else if (parseInt(element.score1) === parseInt(element.score2) && parseInt(element.score1) > 0) { // 平且有得分
              temp1 += 1
              temp2 += 1
            }
          }
        }
      }
      this.scoreShow = '当前比分：   ' + temp1 + '   :   ' + temp2
    },
    // ================   设置比分的dialog end  =================
    async showManualSetPromotionDialog (teamIds, teamMatchTypeId) {
      this.manualSetPromotionData.teamMatchTypeId = teamMatchTypeId // 设置比赛类型id
      getSeasonmatchScore({
        teamIds: teamIds,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      }).then((res) => {
        this.manualSetPromotionTeamData = res.data
        this.manualSetPromotionDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    selectPromotionTeam (data) {
      this.manualSetPromotionData.teamId = []
      data.forEach(element => {
        this.manualSetPromotionData.teamId.push(element.id)
      })
    },
    async manualSetPromotion () {
      if (this.manualSetPromotionData.teamId.length === 0) {
        return this.$message.warning('请选择晋级队伍')
      }
      this.manualSetPromotionData.raceSchedule = this.raceSchedule
      this.manualSetPromotionData.level = this.level
      const { data: res } = await this.$http.get('/erp/academic/judge/itso/setPromoTeamsByHand', {
        params: this.manualSetPromotionData,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      })
      console.log('manualSetPromotion', res)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('设置晋级成功')
      this.getMatchInfo()
      this.manualSetPromotionDialogVisable = false
    },
    async showManualGroupMatchDialog (teamIds, teamMatchTypeId) {
      this.manualGroupMatchData.teamMatchTypeId = teamMatchTypeId // 设置比赛类型id
      const { data: res } = await this.$http.get('/erp/academic/judge/itso/getSchoolClubTeamName', {
        params: {
          teamIds: teamIds,
          level: this.level,
          activityType: this.$chnEngStatusCode.itsoActivityType
        }
      })
      console.log('showManualGroupMatchDialog', res)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.manualGroupMatchTeamData = res.data
      this.manualGroupMatchDialogVisable = true
    },
    addManualGroupMatchGroup () {
      this.manualGroupMatchData.group.push(
        {
          teamIds: [],
          level: this.level,
          activityType: this.$chnEngStatusCode.itsoActivityType
        }
      )
    },
    reduceManualGroupMatchGroup () {
      this.manualGroupMatchData.group.splice(
        this.manualGroupMatchData.group.length - 1, 1
      )
    },
    formatterManualGroupMatchTeamName (teamIds) {
      var tempName = []
      teamIds.forEach(element1 => {
        this.manualGroupMatchTeamData.forEach(element2 => {
          if (element2.id === element1) {
            tempName.push(element2.teamName)
          }
        })
      })
      return tempName.join(',')
    },
    async manualGroupMatch () {
      if (this.manualGroupMatchData.group.length === 0) {
        return this.$message.warning('至少添加一组比赛')
      }
      this.manualGroupMatchData.raceSchedule = this.raceSchedule
      this.manualGroupMatchData.level = this.level
      this.manualGroupMatchData.names = []
      this.manualGroupMatchData.groups = []
      var tempStop = false
      this.manualGroupMatchData.group.forEach(element => {
        if (element.level === '' || element.teamIds.length === 0) {
          tempStop = true
        }
        this.manualGroupMatchData.names.push(element.level)
        this.manualGroupMatchData.groups.push(element.teamIds.join('-'))
      })
      if (tempStop) {
        return this.$message.warning('请完善所有信息')
      }
      const { data: res } = await this.$http.get('/erp/academic/judge/itso/groupPromoTeamsByHand', {
        params: this.manualGroupMatchData,
        level: this.level,
        activityType: this.$chnEngStatusCode.itsoActivityType
      })
      console.log('manualGroupMatch', res)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('组赛成功')
      this.getMatchInfo()
      this.manualGroupMatchDialogVisable = false
    },
    // 批量处理战队对阵信息
    selectionTeamToeditor (val) {
      console.log('val', val)
    },
    // 关闭弹窗后取消效验显示
    resetForm (formRef) {
      this.$refs[formRef].clearValidate()
    },
    saveChangeData () {
      if (this.changeSchduleType === 'round') {
        this.changeModel.round = this.changeData
        editMatchRound(this.changeModel).then((res) => {
          this.$message.success('保存成功')
          this.getSchduleDataList('saveChange')
          this.changeRoundOrrankVisible = false
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        this.changeModel.promotionRuleNum = this.changeData
        editPromotionRuleNum(this.changeModel).then((res) => {
          this.$message.success('保存成功')
          this.changeRoundOrrankVisible = false
          this.getSchduleDataList('saveChange')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    changeProject (rowData, type) {
      this.changeSchduleType = type
      this.changeModel = {
        id: rowData.id
      }
      this.changeRoundOrrankVisible = true
      this.changeData = (type === 'round' ? rowData.round : rowData.promotionRuleNum)
    },
    integralRateFormatter (data) {
      return Math.round(data.integralRate * 10000) / 100 + '%'
    },
    schedulGroupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.level + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  },
  computed: {
    // 判断设置参赛队、分组晋级策略、上传抽签是否可重置
    isReset: function () {
      if (this.matchInfo.teamMatchInfo) {
        if (this.matchInfo.teamMatchInfo.length > 0) {
          if (this.matchInfo.teamMatchInfo[parseInt(this.matchManagerActiveIndex)].list.length > 0) {
            for (let index = 0; index < this.matchInfo.teamMatchInfo[parseInt(this.matchManagerActiveIndex)].list.length; index++) {
              const element = this.matchInfo.teamMatchInfo[parseInt(this.matchManagerActiveIndex)].list[index]
              if (element.matchStatus !== '0') {
                return false
              }
            }
          }
        }
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
    text-align: right;
}
.el-table {
    margin-top: 20px;
    z-index: 99;
}
.typeExplain_class {
    color: red;
}
.operating-instruction {
    font-size: 14px;
    color: color;
}
.blue-font-color {
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
    color: rgb(54, 54, 240);
}
</style>
